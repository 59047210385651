<script lang="ts" setup>
import { PhoneIcon } from '@cfgtech/icons'
import { CfgButton, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { ISbRichtext } from 'storyblok-js-client'
import { defaultManager } from '~/constants/content'

const props = withDefaults(defineProps<{
  managerName?: string
  managerPhone?: string
  managerPhoto?: string
  description?: string | ISbRichtext
  showCtaButton?: boolean
  title?: string
}>(), {
  managerName: defaultManager.name,
  managerPhone: defaultManager.phone,
  managerPhoto: '',
  description: '',
  showCtaButton: false,
  title: '',
})

const { t } = useI18n()

const renderedPhone = computed(() => props.managerPhone?.replace(/ /g, '&nbsp;'))

const { onCallpageContactCardOpenEvent } = useDataLayer()

const resultDescription = computed(() => renderRichText(props.description as ISbRichtext) || t('contact.callMe.all'))

const managerPhoneWithoutSpaces = computed(() => props.managerPhone?.replace(/\s/g, ''))
</script>

<template>
  <div class="flex gap-x-5">
    <div class="size-14 shrink-0 overflow-hidden rounded-full border border-grey-stroke sm:size-[4.5rem]">
      <LazyNuxtImg
        :alt="managerName"
        class="size-full object-cover"
        fit="inside"
        format="webp"
        height="70"
        loading="lazy"
        placeholder
        quality="80"
        :src="managerPhoto || '/manager-photo.jpg'"
        width="70"
      />
    </div>

    <div class="grow">
      <div>
        <CfgTypography :size="CfgTypographySizes.h2" tag-name="p">
          <b>{{ title || $t('contact.callMe.title') }}</b>
        </CfgTypography>

        <CfgTypography
          class="mt-2 text-grey-300"
          :html="resultDescription"
          :size="CfgTypographySizes.md"
          tag-name="div"
        />
      </div>

      <CfgTypography
        class="mb-4 pt-2"
        :size="CfgTypographySizes.sm"
        tag-name="p"
      >
        <strong>{{ managerName }}</strong>

        <br>

        <CfgButton
          v-if="showCtaButton"
          as="a"
          class="mt-2 w-fit px-3"
          href="#cp-widget"
          icon-placement="left"
          small
          @click="onCallpageContactCardOpenEvent()"
        >
          {{ $t('contact.callMe.cta') }}

          <template #icon>
            <PhoneIcon />
          </template>
        </CfgButton>

        <a
          v-else
          class="lg:transition-colors lg:duration-200 lg:hover:text-brand"
          :href="`tel:${managerPhoneWithoutSpaces}`"
          v-html="renderedPhone"
        />
      </CfgTypography>
    </div>
  </div>
</template>
