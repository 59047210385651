<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'

const props
  = defineProps<{
    boast?: string
    title?: string
    preDescription?: string
    description?: string
    formTitle?: string
    formDescription?: string
    managerDescription?: string
    managerName?: string
    managerPhone?: string
    managerPhoto?: string
    managerTitle?: string
  }>()
</script>

<template>
  <div class="grid-cols-12 rounded-[inherit] lg:grid">
    <!-- TEXT (DESKTOP ONLY) -->
    <div class="col-span-12 hidden lg:col-span-7 lg:block">
      <div class="flex gap-x-7 pl-6 pr-14 pt-6 lg:pt-7">
        <div class="w-[65px] shrink-0">
          <NuxtImg
            alt="Logo CFG"
            class="pointer-events-none h-full select-none object-contain object-top"
            loading="lazy"
            src="/img/cfg.svg"
          />
        </div>

        <div class="flex flex-col pb-10">
          <CfgTypography
            class="text-sm uppercase text-grey-500"
            :size="CfgTypographySizes.sm"
            tag-name="p"
          >
            {{ props.boast || $t('common.newsletter.aboutUs.boast') }}
          </CfgTypography>

          <CfgTypography
            class="mt-1 text-center lg:text-left"
            :html="props.title || $t('common.newsletter.aboutUs.title')"
            :size="CfgTypographySizes.h2"
            tag-name="h2"
          />

          <CfgTypography
            class="mt-5 text-h3-mobile"
            :size="CfgTypographySizes.md"
            tag-name="p"
          >
            {{ props.preDescription || $t('common.newsletter.aboutUs.preDescription') }}
          </CfgTypography>

          <CfgTypography
            class="mt-6 text-grey-400"
            :size="CfgTypographySizes.md"
            tag-name="p"
          >
            {{ props.description || $t('common.newsletter.aboutUs.description') }}
          </CfgTypography>
        </div>
      </div>

      <div class="col-span-12 flex border-t border-t-grey-stroke px-6 pb-6 pt-10 lg:pb-0">
        <ContactCallMe
          :description="props.managerDescription"
          :manager-name="props.managerName"
          :manager-phone="props.managerPhone"
          :manager-photo="props.managerPhoto"
          :title="props.managerTitle"
        />
      </div>
    </div>

    <!-- FORM -->
    <div id="contact-form" class="relative overflow-hidden bg-brand-200 px-6 py-8 lg:col-span-5 lg:px-7">
      <div class="lg:mx-auto lg:max-w-[480px]">
        <CfgTypography
          class="mb-3 text-brand"
          :size="CfgTypographySizes.h3"
          tag-name="h3"
        >
          <span v-html="props.formTitle || $t('issue.heroSection.leadDialog.title')" />
        </CfgTypography>

        <CfgTypography
          class="pb-6 text-dark-200"
          :size="CfgTypographySizes.md"
          tag-name="p"
        >
          <span v-html="props.formDescription || $t('issue.heroSection.leadDialog.descriptionContact')" />
        </CfgTypography>

        <div class="w-full">
          <FormContact />
        </div>
      </div>
    </div>
  </div>
</template>
