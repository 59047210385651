<script lang="ts" setup>
import { objectEntries } from '@vueuse/core'
import type { FormPresetExposed } from '../Presets/types'
import type { ContactForm, ContactFormSchema } from './types'
import { leadSubmit } from '~/components/Form/Subscribe/factory/api'
import { DataLayerEventsEnum } from '~~/types'
// composables
const { onNewsletterFormSubmitEvent } = useDataLayer()

// data
const formRef = ref<FormPresetExposed | null>(null)
const { t } = useI18n()
const sentStates = reactive({ success: false, error: false })
const sendState = computed(() => objectEntries(sentStates).find(([_, value]) => Boolean(value))?.[0] || null)

const isStatusModalOpened = ref(false)
const contactFormSchema: ContactFormSchema = {
  note: { show: true, required: false },
  email: { show: true, required: true },
  phone: { show: true, required: true },
  name: { show: true, required: true },
}
const pending = ref(false)
const route = useRoute()

const resolvedSource = computed<DataLayerEventsEnum>(() => {
  if (route.path === '/pro-emitenty') {
    return DataLayerEventsEnum.SrovIssuerInquiryFormSubmit
  }
  else {
    return DataLayerEventsEnum.SrovInquiryFormSubmit
  }
})

async function handleFormSubmit(results: ContactForm) {
  pending.value = true
  try {
    await leadSubmit({
      ...results,
      ...(results.email?.trim()?.length ? { email: results.email } : {}),
      note: `${t('issue.heroSection.leadDialog.form.notePrefix')} ${results.note || t('issue.heroSection.leadDialog.form.noteNotFilled')}`,
    }, resolvedSource.value)

    handleSendSuccess()
  }
  catch (_) {
    handleSendError()
  }
  finally {
    pending.value = false
  }
}

function handleSendSuccess() {
  onNewsletterFormSubmitEvent()

  sentStates.success = true
  isStatusModalOpened.value = true

  formRef.value?.resetForm()
}

function handleSendError() {
  sentStates.success = false
  sentStates.error = true
  isStatusModalOpened.value = true
}
</script>

<template>
  <FormPresetsContactForm
    ref="formRef"
    :form-schema="contactFormSchema"
    :pending="pending"
    @submit="handleFormSubmit"
  />

  <ClientOnly>
    <LazyFormSendStateProvider
      v-model:isOpened="isStatusModalOpened"
      :success-title="$t('issue.heroSection.leadDialog.success.title')"
      :type="sendState"
    />
  </ClientOnly>
</template>
